import * as crypto from 'crypto-js';
import React, {useState} from 'react';

function App() {
const merchantCode = 'M35352'
const merchantKey = '5l4soIj5M8';

const [refNo, setRefNo] = useState('')
const [signature, setSignature] = useState('')


// const merchantCode = "M02590";
// const merchantKey = 'cdv3USqOg3'
const api_url = "https://payment.ipay88.com.my/epayment/entry.asp"


function generateHmacSha512Signature(merchantKey, merchantCode, refNo, amount, currency) {
    const data = `${merchantKey}${merchantCode}${refNo}${amount.replace('.', '')}${currency}`;
    console.log("String to hash:", data)
    return crypto.HmacSHA512(data, merchantKey);
  }

async function createPayment() {
    const refNo = `ORDER_${Date.now()}`; // Unique reference number
    const amount = '1.00'; // Example: 100.00 (two decimal places)
    const currency = "MYR"; // Malaysian Ringgit
    const returnUrl = 'https://wf-ipay88-api.sotatek.works/response';
    const backendUrl = 'https://wfstudiobe.morpheuslabs.io/api/v1/webhooks/y8g4ski8pfudgmx';
    const signature = generateHmacSha512Signature(
        merchantKey,
        merchantCode,
        refNo,
        amount,
        currency
      );
    console.log("Signature: ", signature.toString())
    const paymentData = {
        MerchantCode: merchantCode,
        RefNo: refNo,
        Amount: amount,
        Currency: currency,
        ProdDesc: "Test Product",
        UserName: `Quan ${Date.now()}`,
        UserEmail: "quan.hap@sotatek.com",
        UserContact: "0123456789",
        Signature: signature.toString() + "1",
        ResponseURL: returnUrl,
        BackendURL: backendUrl,
        SignatureType: 'HMACSHA512',
        Lang: 'UTF-8',
        // PaymntId: Date.now(),
    };
    
    try {
        console.log({paymentData})
        setSignature(paymentData.Signature)
        setRefNo(paymentData.RefNo)

        // const response = await fetch(api_url, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
        //   },
        //   body: new URLSearchParams({
        //     ...paymentData
        //   }),
        //   // mode: 'no-cors',
        //   redirect: 'follow'
        // })

        // console.log({response})

    } catch (error) {
        console.error('Payment Request Error:', error.message);
    }
}


  const handleSubmit = async () => {
    await createPayment()
  }

  const queryStatus = async () => {
    const url = "https://payment.ipay88.com.my/epayment/webservice/TxInquiryCardDetails/TxDetailsInquiry.asmx";
    
  const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*',
          },
          body: new URLSearchParams({
            MerchantCode: 'M35352',
            ReferenceNo: 'ORDER_1734059285083',
            Amount: '1.00',
            Version: 4
          }),
          mode: 'no-cors',
          // redirect: 'follow'
        })
  }

  return (
    <div>
      <button onClick={handleSubmit}>Generate data</button>

      <form method="post" target='_blank' name="ePayment" action="https://payment.ipay88.com.my/ePayment/entry.asp">
        <input  name="MerchantCode" value="M35352" /> <br></br>
        <input  name="PaymentId" value="" /> <br></br>
        <input  name="RefNo" value={refNo} /> <br></br>
        <input  name="Amount" value="1.00" /> <br></br>
        <input  name="Currency" value="MYR" /> <br></br>
        <input  name="ProdDesc" value="Test Prods" /> <br></br>
        <input  name="UserName" value="Quan Ha[p" /> <br></br>
        <input  name="UserEmail" value="quan.hap@sotatek.com" /> <br></br>
        <input  name="UserContact" value="0126500100" /> <br></br>
        <input  name="Remark" value="" /> <br></br>
        <input  name="Lang" value="UTF-8" /> <br></br>
        <input  name="SignatureType" value="HMAC-SHA512" /> <br></br>
        <input  name="Signature"
        value={signature} /> <br></br>
        <input  name="ResponseURL" value="https://wf-ipay88-api.sotatek.works/response" /> <br></br>
        <input  name="BackendURL" value="https://wfstudiobe.morpheuslabs.io/api/v1/webhooks/y8g4ski8pfudgmx" /> <br></br>
        <input type="submit" value="Proceed with Payment" name="Submit" />
      </form>

      <button onClick={queryStatus}>Query Status</button>
    </div>
  );
}

export default App;




